<template>
    <div class="notification">
        <button class="delete"></button>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name:"MainNotification"
}
</script>

<style lang="scss" scoped>

</style>